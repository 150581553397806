import { Record, Array as ArrayType, Number, String, Null } from 'runtypes';
import { DID, KID, Url } from './base-types';

import { PublicKey, PublicInfo } from './identity';

/** Article Package Information */
const publicSquare = Record({
    package: Record({
        geo: String,
        politicalSubdivison: String,
        publishDate: Number,
        lang: String,
        title: String,
        description: String,
        image: String,
        body: String,
        canonicalUrl: Url,
        references: Record({
            content: Record({
                reply: String,
                amplify: String,
                like: String,
            }),
        }),
    }),
});

/** Article Provenance */
const provenance = Record({
    signature: String,
    jwk: PublicKey,
    publisher: PublicInfo,
});

/** Article Files */
const file = Record({
    name: String,
    offset: Number,
    length: String,
});

/** Article Run Type - Condensed/Sectionalized Aspects of the Article */
const Article = Record({
    name: String,
    infoHash: String,
    created: Number,
    createdBy: String,
    urlList: ArrayType(String),
    announce: ArrayType(String),
    files: ArrayType(file),
    provenance: provenance,
    info: Record({
        publicSquare: publicSquare,
    }),
});

/** Skeleton for Article */
const ArticleSkeleton = Record({
    body: String,
    description: String,
    lang: String,
    publishDate: Number,
    title: String,
    geo: String,
    politicalSubdivision: String,
    image: String,
    canonicalUrl: Url,
    reply: String.optional(),
});

/** Article as returned by the feed - All Compiled Aspects of the Article */
const FeedArticle = Record({
    /** Article Body */
    body: String,
    /** Date of Article Broadcast */
    broadcastDate: Number,
    /** Article Description */
    description: String,
    /** Associated Decentralized Identity */
    identity: DID,
    /** DID Key */
    key: KID,
    /** Hash of Torrent's info and content */
    infoHash: String,
    blindhash: Number.Or(Null).optional(),
    /** Article Origin Language */
    lang: String,
    /** Article's Infomation about Information or its Metainformation */
    metainfo: Article,
    /** Publish Date of Article */
    publishDate: Number,
    /** Title of Article */
    title: String,
    /** Article's GeoTag */
    geo: String.Or(Null).optional(),
    /** Political Subdivision of DID */
    politicalSubdivision: String.Or(Null).optional(),
    /** Amplified Content */
    contentAmplify: Number.Or(Null).optional(),
    /** Article Likes */
    contentLike: Number.Or(Null).optional(),
    /** Article Replies */
    contentReply: Number.Or(Null).optional()
});

/** Json Web Signature of Article
 * @returns true or error message
 */
const JwsArticle = Record({
    token: String.withConstraint(
        str => /[\d\w-]+\.[\d\w-]+\.[\d\w-]+/g.test(str) || 'Invalid JWS specified'
    ),
})

export { Article, ArticleSkeleton, JwsArticle, FeedArticle }
