import { CompactSign } from "jose-browser-runtime";
import { getKeyPair } from '@/functions/store';
import axios from "axios";

import { List } from '@/types/List';
import { FeedArticle as FeedArticleRun } from "@/types/runtypes/article";
import { FeedArticle } from "@/types/FeedArticle";
import { ActionResponse, DataResponse } from "@/types/Response";

const encoder = new TextEncoder();

/**
* Upload List to Designated URL Endpoint
*/
async function uploadList(list: List): Promise<ActionResponse> {
    const listToUpload = list !== null ? list : null;

    if (listToUpload === null) {
        const m = 'List is Not Defined';
        console.error(m);
        return {
            success: false,
            message: m
        };
    }

    // get key to use with upload
    const keyObj = await getKeyPair(listToUpload.key);
    if (keyObj === false) {
        const m = 'List management key not found';
        console.error(m);
        return {
            success: false,
            message: m
        };
    }

    // get articles and convert to jsonl
    let articleList = '';
    const currentArticles = list.articles;

    for (let i = 0; i < currentArticles.length; i++) {
        const article = currentArticles[i];

        articleList += JSON.stringify(article) + '\n'
    }

    // sign and upload article string
    let JWS;

    try {
        JWS = await new CompactSign(
            encoder.encode(articleList)
        )
        .setProtectedHeader({
            alg: 'ES384',
            kid: listToUpload.key
        })
        .sign(keyObj.private);

        // upload list
        const resp = await axios({
            url: listToUpload.url,
            method: 'PUT',
            data: {
                token: JWS
            },
        });
    } catch (error: any) {
        console.error(error);
        return {
            success: false,
            message: `List upload failed: ${error.message}`
        };
    }

    return {
        success: true,
        message: 'Successfully uploaded list'
    };
}

/**
* Download List from URL
*/
async function downloadList(list: List): Promise<DataResponse> {
    const listToDownload = list !== null ? list : null;

    if (listToDownload === null) {
        const m = 'List is Not Defined';
        console.error(m);
        return {
            success: false,
            message: m
        };
    }

    // parse list download url
    let dl = listToDownload.url;
    dl = dl.replace('/api', '');
    dl += '/index.jsonl';

    // get list from dl url
    const newArticles = [];

    let respData;
    try {
        // dl list
        const resp = await axios.get(dl);

        // validate response
        if (typeof resp.data == 'undefined' || resp.data === '') {
            const m = `${resp.data === '' ? 'Empty' : 'Invalid' } list data response`;
            console.error(m);
            return {
                success: false,
                message: m
            };
        }

        respData = resp.data;
    } catch (error: any) {
        console.error(error);
        return {
            success: false,
            message: `List data retrieval failed: ${error.message}`
        };
    }

    // if data is not string, validate and add object
    if (typeof respData === 'object') {
        let parsedItem: FeedArticle;
        try {
            parsedItem = FeedArticleRun.check(respData);
        } catch (error: any) {
            console.error(error);
            return {
                success: false,
                message:  `Feed Article validation failed: ${error.message}`
            };
        }

        newArticles.push(parsedItem);
    } else if (typeof respData === 'string') {
        // parse data
        const rawList = respData.split("\n");
        for (let i = 0; i < rawList.length; i++) {
            const item = rawList[i];
            if (item === '') continue;
            let parsedItem: FeedArticle;
            try {
                parsedItem = FeedArticleRun.check(JSON.parse(item));
            } catch (error: any) {
                console.error(error);
                return {
                    success: false,
                    message:  `Feed Article validation failed: ${error.message}`
                };
            }

            newArticles.push(parsedItem);
        }
    }

    listToDownload.articles = newArticles;

    return {
        success: true,
        message: 'Successfully retrieved the list',
        data: listToDownload
    };
}

export { uploadList, downloadList }
